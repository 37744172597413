import PhotoSwipeLightbox from 'photoswipe/lightbox';
import PhotoSwipe from 'photoswipe';

const componentGallery = () => {
    const galleries = document.querySelectorAll('.pswp-gallery');

    if (galleries) {
        const lightbox = new PhotoSwipeLightbox({
            gallery: galleries,
            children: 'a',
            showHideAnimationType: 'zoom',
            padding: { top: 40, bottom: 40, left: 40, right: 40 },
            pswpModule: PhotoSwipe
        });
        lightbox.init();
    }
};

export default componentGallery;