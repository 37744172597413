import barba from "@barba/core";
import barbaPrefetch from "@barba/prefetch";
import { onceHome, leavePage, enterPage } from "./transitions";
import { sectionFull, sectionHalf, sectionStory } from "./sections";
import { componentAnimation, componentAnimatedSVG, componentAccordion, componentChart, componentCounterStart, componentCounterGrid, componentSideTeaser, componentGallery } from "./components";
import logoBar from "./logo.js";
import * as Navigation from "./navigation.js";
import sideNavigation from "./sidenavigation.js";
import sideControl from "./sidecontrol.js";
import { StepNavigation } from "./stepnavigation.js";

import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import { ScrollToPlugin } from "gsap/ScrollToPlugin";

gsap.registerPlugin(ScrollTrigger, ScrollToPlugin);
barba.use(barbaPrefetch);

// Load Stepnavigaton Class
customElements.define("step-navigation", StepNavigation);

// Load import functions global
function initReload() {
	logoBar();
	Navigation.menuToggle();
	Navigation.navState();
	sectionFull();
	sectionHalf();
	sectionStory();
	componentAnimation();
	componentAnimatedSVG();
	componentAccordion();
	componentChart();
	componentCounterGrid();
	componentCounterStart();
	componentGallery();
}

// Load import functions home
function initHomeReload() {
	sideNavigation();
	componentSideTeaser();
}

// Load import functions detailsites
function initDetailReload() {
	sideControl();
}

// Mobile Height Hack
const mobileHeight = () => {
	let vh = window.innerHeight * 0.01;
	document.documentElement.style.setProperty('--vh', `${vh}px`);
}

mobileHeight();

window.addEventListener('resize', () => {
	mobileHeight();
});

barba.hooks.leave(() => {
	const logo = document.querySelector('.logo');
	logo.remove();
});

barba.hooks.beforeEnter((data) => {
	window.scrollTo(0, 0);
	initReload();
});

barba.init({
	debug: false,
	preventRunning: true,
	views: [
		{
			namespace: "home",
			beforeEnter() {
				initHomeReload();

				const sideControl = document.querySelector(".side-control");
				if (sideControl) {
					sideControl.style.display = "none";
				}
			},
		},
		{
			namespace: "detail",
			beforeEnter() {
				const sideControl = document.querySelector(".side-control");
				if (sideControl) {
					sideControl.style.display = "flex";
				}

				initDetailReload();
			},
		},
	],
	transitions: [
		// {
		// 	once({ next }) {
		//         onceHome(next.container);
		// 	},
		// 	to: {
		// 		namespace: ["home"],
		// 	},
		// },
		{
			leave({ current }) {
				const done = this.async();
				leavePage(current.container, done);
			},
			enter({ next }) {
				enterPage(next.container);
				Navigation.menuToggleInstant();
			},
		},
	],
});
