import * as echarts from 'echarts';

const componentChart = () => {

    const neukredite = document.getElementById('neukredite')
    if (neukredite) {
        var echartNeukredite = echarts.init(neukredite);
        echartNeukredite.setOption({
            title: {
                text: 'Neukreditvergabe in Millionen Euro',
                bottom: 10,
                right: '10%',
                textStyle: {
                    fontSize: 13,
                },
            },
            tooltip: {},
            xAxis: {
                splitLine: {
                    show: true
                },
                data: ['2020', '2021', '2022']
            },
            yAxis: {
                axisLine: {
                    show: true
                }
            },
            series: [
                {
                    name: 'Neukredite',
                    type: 'bar',
                    // itemStyle: {
                    //   borderRadius: [50, 50, 0, 0],
                    // },
                    barWidth: "50%",
                    data: [
                        {
                            value: 671,
                            itemStyle: {
                                color: 'rgba(100, 155, 215, .5)'
                            }
                        },
                        {
                            value: 773,
                            itemStyle: {
                                color: 'rgba(50, 125, 200, .5)'
                            }
                        },
                        {
                            value: 719,
                            itemStyle: {
                                color: 'rgba(255, 102, 0, .5)'
                            }
                        },
                    ],
                    padding: 5
                }
            ]
        });
    }

    const zinsueberschuss = document.getElementById('zinsueberschuss')
    if (zinsueberschuss) {
        var echartZinsueberschuss = echarts.init(zinsueberschuss);
        echartZinsueberschuss.setOption({
            title: {
                text: 'Zinsüberschuss in Millionen Euro',
                bottom: 10,
                right: '10%',
                textStyle: {
                    fontSize: 13,
                },
            },
            tooltip: {},
            xAxis: {
                axisLine: {
                    show: true
                }
            },
            yAxis: {
                splitLine: {
                    show: true
                },
                data: ['2020', '2021', '2022'],
                inverse: true
            },
            series: [
                {
                    name: 'Zinsüberschuss',
                    type: 'bar',
                    // itemStyle: {
                    //   borderRadius: [0, 50, 50, 0],
                    // },
                    barWidth: "50%",
                    data: [
                        {
                            value: 53.9,
                            itemStyle: {
                                color: 'rgba(100, 155, 215, .5)'
                            }
                        },
                        {
                            value: 54.4,
                            itemStyle: {
                                color: 'rgba(50, 125, 200, .5)'
                            }
                        },
                        {
                            value: 60.6,
                            itemStyle: {
                                color: 'rgba(255, 102, 0, .5)'
                            }
                        },
                    ],
                    padding: 5
                }
            ]
        });
    }


    const eigenmittel = document.getElementById('eigenmittel')
    if (eigenmittel) {
        var echartEigenmittel = echarts.init(eigenmittel);
        echartEigenmittel.setOption({
            title: {
                text: 'Eigenmittel in Millionen Euro',
                bottom: 10,
                right: '10%',
                textStyle: {
                    fontSize: 13,
                },
            },
            tooltip: {},
            xAxis: {
                splitLine: {
                    show: true
                },
                data: ['2020', '2021', '2022']
            },
            yAxis: {
                axisLine: {
                    show: true
                }
            },
            series: [
                {
                    name: 'Eigenmittel',
                    type: 'bar',
                    // itemStyle: {
                    //   borderRadius: [50, 50, 0, 0],
                    // },
                    barWidth: "50%",
                    data: [
                        {
                            value: 343,
                            itemStyle: {
                                color: 'rgba(100, 155, 215, .5)'
                            }
                        },
                        {
                            value: 348,
                            itemStyle: {
                                color: 'rgba(50, 125, 200, .5)'
                            }
                        },
                        {
                            value: 385,
                            itemStyle: {
                                color: 'rgba(255, 102, 0, .5)'
                            }
                        },
                    ],
                    padding: 5
                }
            ]
        });
    }

    const bilanzsumme = document.getElementById('bilanzsumme')
    if (bilanzsumme) {
        var echartBilanzsumme = echarts.init(bilanzsumme);
        echartBilanzsumme.setOption({
            color: [
                '#ff6600'
            ],
            tooltip: {
                show: false,
            },
            legend: {
                show: false,
            },
            series: [
                {
                    name: 'Bilanzsumme',
                    type: 'pie',
                    radius: ['80%', '90%'],
                    avoidLabelOverlap: false,
                    label: {
                        show: false,
                    },
                    emphasis: {
                        label: {
                            show: false,
                        }
                    },
                    labelLine: {
                        show: false
                    },
                    data: [
                        { value: 4106887611, name: 'Bilanzsumme' },
                    ]
                }
            ]
        });
    }


    if (zinsueberschuss && neukredite && eigenmittel) {
        window.onresize = function () {
            echartNeukredite.resize();
            echartZinsueberschuss.resize();
            echartEigenmittel.resize();
        };
    }

    if (bilanzsumme) {
        window.onresize = function () {
            echartBilanzsumme.resize();
        };
    }


};

export default componentChart;