import gsap from "gsap";

const componentAnimatedSVG = () => {

    // Animation Volle Ladung
    const animatedVolleLadung = document.getElementById("volle-ladung");

    if (animatedVolleLadung) {
        const tlAnimatedVolleLadung = gsap.timeline({
            paused: true,
            reversed: true,
            defaults: {
                ease: "power3.inOut",
            }
        });

        const power = animatedVolleLadung.getElementById("power");
        const plug = animatedVolleLadung.getElementById("plug");

        gsap.set(power, { transformOrigin: "center" });
        gsap.set(animatedVolleLadung, { pointerEvents: "none" });

        tlAnimatedVolleLadung
            .to(plug, { x: -10 }, 1)
            .to(power, { duration: 0.3, opacity: .5, scale: .75, yoyo: true, repeat: 10 })
            .to(power, { opacity: 1, scale: 1 })
            .to(plug, { x: 0 })
            .set(animatedVolleLadung, { pointerEvents: "auto" });

        const observeOptions = {
            root: null,
            threshold: 0.25
        };

        const observeAni = new IntersectionObserver(function (entries, observer) {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    tlAnimatedVolleLadung.restart();
                    tlAnimatedVolleLadung.play();
                }
            })
            // entries.forEach((entry) => {
            //     if (!entry.isIntersecting) {
            //         return;
            //     } else {
            //         tlAnimatedVolleLadung.play();
            //         observeAni.unobserve(entry.target);
            //     }
            // });
        }, observeOptions);
        observeAni.observe(animatedVolleLadung);


        const testDc = () => {
            console.log('ready');
        };

        animatedVolleLadung.addEventListener('mouseover', () => {
            tlAnimatedVolleLadung.restart();
            tlAnimatedVolleLadung.play();
        });
    }


    // Animation E-Flotte
    const animatedEflotte = document.getElementById("eflotte");

    if (animatedEflotte) {
        const tlAnimatedEflotte = gsap.timeline({
            paused: true,
            reversed: true,
            defaults: {
                ease: "power3.inOut",
            },
        });

        const car1 = animatedEflotte.getElementById("car1");
        const car2 = animatedEflotte.getElementById("car2");
        const lights1 = animatedEflotte.getElementById("lights1");
        const lights2 = animatedEflotte.getElementById("lights2");

        gsap.set([car1, car2], { transformOrigin: "center bottom", opacity: 0, scale: .5 });
        gsap.set(animatedEflotte, { pointerEvents: "none" });

        tlAnimatedEflotte
            .to(car1, { duration: 2, opacity: 1, scale: 1 }, 1)
            .to(lights1, { duration: .2, opacity: 0, yoyo: true, repeat: 2 })
            .to(car2, { duration: 2, opacity: 1, scale: 1, }, "-=1")
            .to(lights2, { duration: .2, opacity: 0, yoyo: true, repeat: 2 })
            .set(animatedEflotte, { pointerEvents: "auto" });

        const observeOptions = {
            root: null,
            threshold: 0.25
        };

        const observeAni = new IntersectionObserver(function (entries, observer) {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    tlAnimatedEflotte.restart();
                    tlAnimatedEflotte.play();
                }
            })
            // entries.forEach((entry) => {
            //     if (!entry.isIntersecting) {
            //         return;
            //     } else {
            //         tlAnimatedEflotte.play();
            //         observeAni.unobserve(entry.target);
            //     }
            // });
        }, observeOptions);
        observeAni.observe(animatedEflotte);

        animatedEflotte.addEventListener('mouseover', () => {
            tlAnimatedEflotte.restart();
            tlAnimatedEflotte.play();
        });
    }


    // Animation Kraft der Sonne
    const animatedKraftSonne = document.getElementById("kraft-sonne");

    if (animatedKraftSonne) {
        const tlanimatedKraftSonne = gsap.timeline({
            paused: true,
            reversed: true,
            defaults: {
                ease: "power3.inOut",
            },
        });

        const sun = animatedKraftSonne.getElementById("sun");
        const solar1 = animatedKraftSonne.getElementById("solar1");
        const solar2 = animatedKraftSonne.getElementById("solar2");

        gsap.set(sun, { opacity: 0, y: 10 });
        gsap.set([solar1, solar2], { transformOrigin: "left bottom", opacity: 0, scale: .5 });
        gsap.set(animatedKraftSonne, { pointerEvents: "none" });

        tlanimatedKraftSonne
            .to(sun, { duration: 2, y: 0, opacity: 1, scale: 1 }, 1)
            .to(solar1, { duration: 2, opacity: 1, scale: 1 })
            .to(solar2, { duration: 2, opacity: 1, scale: 1 }, "-=1")
            .set(animatedKraftSonne, { pointerEvents: "auto" });

        const observeOptions = {
            root: null,
            threshold: 0.25
        };

        const observeAni = new IntersectionObserver(function (entries, observer) {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    tlanimatedKraftSonne.restart();
                    tlanimatedKraftSonne.play();
                }
            })
            // entries.forEach((entry) => {
            //     if (!entry.isIntersecting) {
            //         return;
            //     } else {
            //         tlanimatedKraftSonne.play();
            //         observeAni.unobserve(entry.target);
            //     }
            // });
        }, observeOptions);
        observeAni.observe(animatedKraftSonne);

        animatedKraftSonne.addEventListener('mouseover', () => {
            tlanimatedKraftSonne.restart();
            tlanimatedKraftSonne.play();
        });
    }


    // Animation Grüne Wärme
    const animatedGrueneWaerme = document.getElementById("gruene-waerme");

    if (animatedGrueneWaerme) {
        const tlanimatedGrueneWaerme = gsap.timeline({
            paused: true,
            reversed: true,
            defaults: {
                ease: "power3.inOut",
            },
        });

        const thermo = animatedGrueneWaerme.getElementById("thermo");
        const wave1 = animatedGrueneWaerme.getElementById("wave1");
        const wave2 = animatedGrueneWaerme.getElementById("wave2");
        const wave3 = animatedGrueneWaerme.getElementById("wave3");

        gsap.set(thermo, { transformOrigin: "center bottom", scaleY: 0 });
        gsap.set([wave1, wave2, wave3], { transformOrigin: "center bottom", scaleY: .75, opacity: 0 });
        gsap.set(animatedGrueneWaerme, { pointerEvents: "none" });

        tlanimatedGrueneWaerme
            .to(thermo, { duration: 2, scaleY: 1 }, 1)
            .to([wave1, wave2, wave3], { stagger: { each: 0.2, yoyo: true, repeat: 6 }, duration: 1, opacity: 1, scaleY: 1 })
            .set(animatedGrueneWaerme, { pointerEvents: "auto" });

        const observeOptions = {
            root: null,
            threshold: 0.25
        };

        const observeAni = new IntersectionObserver(function (entries, observer) {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    tlanimatedGrueneWaerme.restart();
                    tlanimatedGrueneWaerme.play();
                }
            })
            // entries.forEach((entry) => {
            //     if (!entry.isIntersecting) {
            //         return;
            //     } else {
            //         tlanimatedGrueneWaerme.play();
            //         observeAni.unobserve(entry.target);
            //     }
            // });
        }, observeOptions);
        observeAni.observe(animatedGrueneWaerme);

        animatedGrueneWaerme.addEventListener('mouseover', () => {
            tlanimatedGrueneWaerme.restart();
            tlanimatedGrueneWaerme.play();
        });
    }

    // Animation Fit ins Büro
    const animatedFitBuero = document.getElementById("fit-buero");

    if (animatedFitBuero) {
        const tlanimatedFitBuero = gsap.timeline({
            paused: true,
            reversed: true,
            defaults: {
                ease: "sine.in",
            },
        });

        const trees = animatedFitBuero.getElementById("trees");
        const clouds = animatedFitBuero.getElementById("clouds");

        gsap.set(clouds, { xPercent: 0 });
        gsap.set(animatedFitBuero, { pointerEvents: "none" });

        tlanimatedFitBuero
            .fromTo(trees, { xPercent: 100 }, { duration: 4, xPercent: -100, repeat: 1 })
            .fromTo(trees, { xPercent: 100 }, { duration: 2, xPercent: 0 })
            .fromTo(clouds, { duration: 10, xPercent: 130 }, { duration: 10, xPercent: 0 }, 0)
            .set(animatedFitBuero, { pointerEvents: "auto" });

        const observeOptions = {
            root: null,
            threshold: 0.25
        };

        const observeAni = new IntersectionObserver(function (entries, observer) {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    tlanimatedFitBuero.restart();
                    tlanimatedFitBuero.play();
                }
            })
            // entries.forEach((entry) => {
            //     if (!entry.isIntersecting) {
            //         return;
            //     } else {
            //         tlanimatedFitBuero.play();
            //         observeAni.unobserve(entry.target);
            //     }
            // });
        }, observeOptions);
        observeAni.observe(animatedFitBuero);

        animatedFitBuero.addEventListener('mouseover', () => {
            tlanimatedFitBuero.restart();
            tlanimatedFitBuero.play();
        });
    }

    // Animation Nachhaltig pendeln
    const animatedNachhaltigPendeln = document.getElementById("nachhaltig-pendeln");

    if (animatedNachhaltigPendeln) {
        const tlanimatedNachhaltigPendeln = gsap.timeline({
            paused: true,
            reversed: true,
            defaults: {
                ease: "sine.inOut",
            },
        });

        const hand = animatedNachhaltigPendeln.getElementById("hand");

        gsap.set(hand, { transformOrigin: "bottom center", y: 3, rotation: 0 });
        gsap.set(animatedNachhaltigPendeln, { pointerEvents: "none" });

        tlanimatedNachhaltigPendeln
            .fromTo(hand, { duration: .5, rotation: 0, yoyo: true, repeat: 7 }, { duration: .5, rotation: 10, yoyo: true, repeat: 7 }, 1)
            .to(hand, { duration: .5, rotation: 0 })
            .set(animatedNachhaltigPendeln, { pointerEvents: "auto" });

        const observeOptions = {
            root: null,
            threshold: 0.25
        };

        const observeAni = new IntersectionObserver(function (entries, observer) {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    tlanimatedNachhaltigPendeln.restart();
                    tlanimatedNachhaltigPendeln.play();
                }
            })
            // entries.forEach((entry) => {
            //     if (!entry.isIntersecting) {
            //         return;
            //     } else {
            //         tlanimatedNachhaltigPendeln.play();
            //         observeAni.unobserve(entry.target);
            //     }
            // });
        }, observeOptions);
        observeAni.observe(animatedNachhaltigPendeln);

        animatedNachhaltigPendeln.addEventListener('mouseover', () => {
            tlanimatedNachhaltigPendeln.restart();
            tlanimatedNachhaltigPendeln.play();
        });
    }

    // Animation Rechenpower
    const animatedRechenpower = document.getElementById("rechenpower");

    if (animatedRechenpower) {
        const tlanimatedRechenpower = gsap.timeline({
            paused: true,
            reversed: true,
            defaults: {
                ease: "power3.inOut",
            },
        });

        const net1 = animatedRechenpower.getElementById("net1");
        const net2 = animatedRechenpower.getElementById("net2");
        const display1 = animatedRechenpower.getElementById("display1");
        const display2 = animatedRechenpower.getElementById("display2");

        gsap.set(net1, { transformOrigin: "top right", opacity: 0, scale: 0 });
        gsap.set(net2, { transformOrigin: "top left", opacity: 0, scale: 0 });
        gsap.set([display1, display2], { opacity: 0 });
        gsap.set(animatedRechenpower, { pointerEvents: "none" });

        tlanimatedRechenpower
            .to(net1, { duration: 2, opacity: 1, scale: 1 })
            .to(net2, { duration: 2, opacity: 1, scale: 1 }, "-=.5")
            .to(display1, { duration: 1, opacity: 1 }, "-=2")
            .to(display2, { duration: 1, opacity: 1 }, "-=.5")
            .set(animatedRechenpower, { pointerEvents: "auto" });

        const observeOptions = {
            root: null,
            threshold: 0.25
        };

        const observeAni = new IntersectionObserver(function (entries, observer) {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    tlanimatedRechenpower.restart();
                    tlanimatedRechenpower.play();
                }
            })
            // entries.forEach((entry) => {
            //     if (!entry.isIntersecting) {
            //         return;
            //     } else {
            //         tlanimatedRechenpower.play();
            //         observeAni.unobserve(entry.target);
            //     }
            // });
        }, observeOptions);
        observeAni.observe(animatedRechenpower);

        animatedRechenpower.addEventListener('mouseover', () => {
            tlanimatedRechenpower.restart();
            tlanimatedRechenpower.play();
        });
    }

    // Animation Edle Stoffe
    const animatedEdleStoffe = document.getElementById("edle-stoffe");

    if (animatedEdleStoffe) {
        const tlanimatedEdleStoffe = gsap.timeline({
            paused: true,
            reversed: true,
            defaults: {
                ease: "sine.inOut",
            },
        });

        const circle = animatedEdleStoffe.getElementById("circle");

        gsap.set(circle, { transformOrigin: "center" });
        gsap.set(animatedEdleStoffe, { pointerEvents: "none" });

        tlanimatedEdleStoffe
            .to(circle, { duration: 6, rotation: 360 }, 1)
            .set(animatedEdleStoffe, { pointerEvents: "auto" });

        const observeOptions = {
            root: null,
            threshold: 0.25
        };

        const observeAni = new IntersectionObserver(function (entries, observer) {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    tlanimatedEdleStoffe.restart();
                    tlanimatedEdleStoffe.play();
                }
            })
            // entries.forEach((entry) => {
            //     if (!entry.isIntersecting) {
            //         return;
            //     } else {
            //         tlanimatedEdleStoffe.play();
            //         observeAni.unobserve(entry.target);
            //     }
            // });
        }, observeOptions);
        observeAni.observe(animatedEdleStoffe);

        animatedEdleStoffe.addEventListener('mouseover', () => {
            tlanimatedEdleStoffe.restart();
            tlanimatedEdleStoffe.play();
        });
    }

    // Animation Sortenrein
    const animatedSortenrein = document.getElementById("sortenrein");

    if (animatedSortenrein) {
        const tlanimatedSortenrein = gsap.timeline({
            paused: true,
            reversed: true,
            defaults: {
                ease: "power3.inOut",
            },
        });

        const circle = animatedSortenrein.getElementById("circle");

        gsap.set([arrow1, arrow2, arrow3], { opacity: 0 });
        gsap.set(animatedSortenrein, { pointerEvents: "none" });

        tlanimatedSortenrein
            .to([arrow1, arrow3, arrow2], { stagger: { each: 0.2, yoyo: true, repeat: 6 }, duration: 1, opacity: 1, scaleY: 1 })
            .set(animatedSortenrein, { pointerEvents: "auto" });

        const observeOptions = {
            root: null,
            threshold: 0.25
        };

        const observeAni = new IntersectionObserver(function (entries, observer) {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    tlanimatedSortenrein.restart();
                    tlanimatedSortenrein.play();
                }
            })
            // entries.forEach((entry) => {
            //     if (!entry.isIntersecting) {
            //         return;
            //     } else {
            //         tlanimatedSortenrein.play();
            //         observeAni.unobserve(entry.target);
            //     }
            // });
        }, observeOptions);
        observeAni.observe(animatedSortenrein);

        animatedSortenrein.addEventListener('mouseover', () => {
            tlanimatedSortenrein.restart();
            tlanimatedSortenrein.play();
        });
    }

    // Animation Erfrischend Ökologisch
    const animatedErfrischendOekologisch = document.getElementById("erfrischend-oekologisch");

    if (animatedErfrischendOekologisch) {
        const tlanimatedErfrischendOekologisch = gsap.timeline({
            paused: true,
            reversed: true,
            defaults: {
                ease: "power3.inOut",
            },
        });

        const water1 = animatedErfrischendOekologisch.getElementById("water1");
        const water2 = animatedErfrischendOekologisch.getElementById("water2");
        const water2mask = animatedErfrischendOekologisch.getElementById("water2mask");

        gsap.set([water1, water2, water2mask], { transformOrigin: "bottom center" });
        gsap.set(animatedErfrischendOekologisch, { pointerEvents: "none" });

        tlanimatedErfrischendOekologisch
            .to(water1, { duration: 2, scaleY: .5, yoyo: true, repeat: 3 }, 1)
            .fromTo(water2mask, { y: 35}, { duration: 6, y: 0, }, water1)
            .set(animatedErfrischendOekologisch, { pointerEvents: "auto" });

        const observeOptions = {
            root: null,
            threshold: 0.25
        };

        const observeAni = new IntersectionObserver(function (entries, observer) {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    tlanimatedErfrischendOekologisch.restart();
                    tlanimatedErfrischendOekologisch.play();
                }
            })
            // entries.forEach((entry) => {
            //     if (!entry.isIntersecting) {
            //         return;
            //     } else {
            //         tlanimatedErfrischendOekologisch.play();
            //         observeAni.unobserve(entry.target);
            //     }
            // });
        }, observeOptions);
        observeAni.observe(animatedErfrischendOekologisch);

        animatedErfrischendOekologisch.addEventListener('mouseover', () => {
            tlanimatedErfrischendOekologisch.restart();
            tlanimatedErfrischendOekologisch.play();
        });
    }

    // Animation Wald retten
    const animatedWaldRetten = document.getElementById("wald-retten");

    if (animatedWaldRetten) {
        const tlanimatedWaldRetten = gsap.timeline({
            paused: true,
            reversed: true,
            defaults: {
                ease: "elastic.out",
            },
        });

        const forest1 = animatedWaldRetten.getElementById("forest1");
        const forest2 = animatedWaldRetten.getElementById("forest2");
        const forest3 = animatedWaldRetten.getElementById("forest3");
        const forest4 = animatedWaldRetten.getElementById("forest4");
        const forest5 = animatedWaldRetten.getElementById("forest5");
        const forest6 = animatedWaldRetten.getElementById("forest6");
        const forest7 = animatedWaldRetten.getElementById("forest7");
        const forest8 = animatedWaldRetten.getElementById("forest8");
        const street = animatedWaldRetten.getElementById("street");

        gsap.set([forest1, forest2, forest3, forest4, forest5, forest6, forest7, forest8], { transformOrigin: "bottom center", opacity: 0, scaleY: .8, rotation: 0 });
        gsap.set(street, { opacity: 0 });
        gsap.set(animatedWaldRetten, { pointerEvents: "none" });

        tlanimatedWaldRetten
            .to([forest1, forest2, forest3, forest4, forest5, forest6, forest7, forest8], { stagger: { each: 0.2 }, rotation: 0, duration: 2, opacity: 1, scaleY: 1 }, 1)
            .fromTo([forest1, forest2, forest3, forest4, forest5, forest6, forest7, forest8], { rotation: 0 }, { stagger: { each: 0.1, from: "random" }, ease: "back.out(2)", duration: 2, rotation: 10 })
            .fromTo([forest1, forest2, forest3, forest4, forest5, forest6, forest7, forest8], { rotation: 10 }, { stagger: { each: 0.1, from: "random" }, ease: "bounce.out", duration: 1, rotation: 0 }, "-=1")
            .to(street, { ease: "sine.inOut", duration: 1, opacity: 1 }, 2.5)
            .set(animatedWaldRetten, { pointerEvents: "auto" });

        const observeOptions = {
            root: null,
            threshold: 0.25
        };

        const observeAni = new IntersectionObserver(function (entries, observer) {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    tlanimatedWaldRetten.restart();
                    tlanimatedWaldRetten.play();
                }
            })
            // entries.forEach((entry) => {
            //     if (!entry.isIntersecting) {
            //         return;
            //     } else {
            //         tlanimatedWaldRetten.play();
            //         observeAni.unobserve(entry.target);
            //     }
            // });
        }, observeOptions);
        observeAni.observe(animatedWaldRetten);

        animatedWaldRetten.addEventListener('mouseover', () => {
            tlanimatedWaldRetten.restart();
            tlanimatedWaldRetten.play();
        });
    }

    // Animation Weniger Mobil
    const animatedWenigerMobil = document.getElementById("weniger-mobil");

    if (animatedWenigerMobil) {
        const tlanimatedWenigerMobil = gsap.timeline({
            paused: true,
            reversed: true,
            defaults: {
                ease: "sine.inOut",
            },
        });

        const catTail = animatedWenigerMobil.getElementById("cat-tail");
        const laptop = animatedWenigerMobil.getElementById("laptop");
        const laptopShine = animatedWenigerMobil.getElementById("laptop-shine");
        var catTailLength = catTail.getTotalLength();

        gsap.set(catTail, { transformOrigin: "bottom right", scale: .75 });
        gsap.set(laptop, { transformOrigin: "bottom center", scaleY: 0 });
        gsap.set(laptopShine, { transformOrigin: "bottom center", scaleY: 0, opacity: 0 });
        gsap.set(animatedWenigerMobil, { pointerEvents: "none" });

        tlanimatedWenigerMobil
            .to(laptop, { duration: 1, scaleY: 1 }, 1)
            .to(laptopShine, { duration: 1, opacity: 1, scaleY: 1 }, "-=1")
            .fromTo(catTail, { scale: 1, rotation: -20, strokeDashoffset: catTailLength }, { duration: 1, scale: 1, rotation: 0, yoyo: true, repeat: 8 }, laptop)
            .set(animatedWenigerMobil, { pointerEvents: "auto" });

        const observeOptions = {
            root: null,
            threshold: 0.25
        };

        const observeAni = new IntersectionObserver(function (entries, observer) {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    tlanimatedWenigerMobil.restart();
                    tlanimatedWenigerMobil.play();
                }
            })
            // entries.forEach((entry) => {
            //     if (!entry.isIntersecting) {
            //         return;
            //     } else {
            //         tlanimatedWenigerMobil.play();
            //         observeAni.unobserve(entry.target);
            //     }
            // });
        }, observeOptions);
        observeAni.observe(animatedWenigerMobil);

        animatedWenigerMobil.addEventListener('mouseover', () => {
            tlanimatedWenigerMobil.restart();
            tlanimatedWenigerMobil.play();
        });
    }



};

export default componentAnimatedSVG;
